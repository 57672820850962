import _ from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApiV2, HitlApi } from '@/api';
import { ContactBooksOrderModes } from '@/constants';
export const useFieldSchemasStore = defineStore('fieldSchemas', {
    state: () => ({
        items: [],
        total: null,
        triggers: [],
    }),
    getters: {
        getFieldSchemaById: (state) => {
            return (id) => {
                return _.find(state.items, { id });
            };
        },
        getFieldSchemaByLabel: (state) => {
            return (label) => {
                return _.find(state.items, { label });
            };
        },
    },
    actions: {
        async getFieldSchemas({ orderDirection = ContactBooksOrderModes.ASC, orderProperty = undefined, take = undefined, skip = undefined, q = undefined, } = {}) {
            const resp = await ContactsApiV2.fieldSchemaApi.listSchemas({
                orderDirection,
                orderProperty,
                size: take,
                from: skip,
                q,
            });
            this.total = resp.total;
            this.items = resp.items;
        },
        async addFieldSchema(payload) {
            await ContactsApiV2.fieldSchemaApi.createSchema(payload);
            await this.getFieldSchemas();
        },
        async deleteFieldSchema(id) {
            await ContactsApiV2.fieldSchemaApi.deleteSchema(id);
            await this.getFieldSchemas();
        },
        _updateFieldSchema(data) {
            const index = _.findIndex(this.items, { id: data.id });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async getFieldSchema(id) {
            const data = await ContactsApiV2.fieldSchemaApi.getSchema(id);
            this._updateFieldSchema(data);
        },
        async updateFieldSchema(id, dto) {
            const data = await ContactsApiV2.fieldSchemaApi.updateSchema(id, dto);
            this._updateFieldSchema(data);
        },
        async getTriggers() {
            this.triggers = await HitlApi.sessions.getSessionInitTriggers();
        },
    },
});
export default useFieldSchemasStore;
