import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "app is-loading" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_expiration_overlay = _resolveComponent("expiration-overlay");
    const _component_router_view = _resolveComponent("router-view");
    const _component_app_page = _resolveComponent("app-page");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_expiration_overlay, {
            "lock-username": _ctx.lockScreenUsername,
            username: _ctx.username,
            "auth-url": _ctx.authUrl,
            "identity-provider": _ctx.identityProvider,
            token: _ctx.token,
            "user-id": _ctx.userId,
            "account-id": _ctx.accountId,
            "cookies-expired": _ctx.cookiesExpired,
            "cookies-another-user": _ctx.cookiesAnotherUser,
            "cookies-no-rights": _ctx.cookiesNoRights,
            "login-overlay-disabled": false,
            onOnUpdateCookieExpiration: _ctx.cookiesRenewHandler
        }, null, 8, ["lock-username", "username", "auth-url", "identity-provider", "token", "user-id", "account-id", "cookies-expired", "cookies-another-user", "cookies-no-rights", "onOnUpdateCookieExpiration"]),
        (_ctx.userHasPermission)
            ? (_openBlock(), _createBlock(_component_app_page, {
                key: 0,
                "account-id": _ctx.accountId,
                "is-loading": _ctx.isLoading,
                token: _ctx.token,
                config: _ctx.config,
                "service-translations": _ctx.serviceTranslations,
                "error-text": _ctx.errorText,
                "show-error": _ctx.showError,
                "onUpdate:showError": _cache[0] || (_cache[0] = ($event) => (_ctx.showError = $event))
            }, {
                default: _withCtx(() => [
                    (_ctx.userHasPermission)
                        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["account-id", "is-loading", "token", "config", "service-translations", "error-text", "show-error"]))
            : _createCommentVNode("", true)
    ]));
}
