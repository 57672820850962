import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-526b8498"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "contacts-pagination" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_pagination_length_selector = _resolveComponent("or-pagination-length-selector");
    const _component_v_pagination = _resolveComponent("v-pagination");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_pagination_length_selector, {
            limit: _ctx.limit,
            total: _ctx.total,
            offset: _ctx.offset,
            onChange: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update:limit', $event)))
        }, null, 8, ["limit", "total", "offset"]),
        (_ctx.total)
            ? (_openBlock(), _createBlock(_component_v_pagination, {
                key: 0,
                modelValue: _ctx.currentPage,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.currentPage) = $event)),
                class: "pagination",
                "data-test": "pagination-list",
                pages: _ctx.pages,
                "range-size": 1,
                "active-color": "transparent"
            }, null, 8, ["modelValue", "pages"]))
            : _createCommentVNode("", true)
    ]));
}
