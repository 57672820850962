import { SharedBookStatus } from '@onereach/types-contacts-api';
import { OrCardCollectionV3 as OrCardCollection, CardCollectionView, } from '@onereach/ui-components';
import { OrderDirection } from '@or-sdk/contacts';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import { useAuth } from '@/stores';
import ContactBooksGridItem from './ContactBooksGridItem.vue';
import ContactBooksListHeader from './ContactBooksListHeader.vue';
import ContactBooksListItem from './ContactBooksListItem.vue';
export default defineComponent({
    name: 'ContactBooksCollection',
    components: {
        OrCardCollection,
        ContactBooksListHeader,
        ContactBooksListItem,
        ContactBooksGridItem,
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        view: {
            type: String,
            required: false,
            default: CardCollectionView.Grid,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    emits: ['open', 'delete', 'deleteWithContacts', 'share', 'search', 'changeView', 'changeOrder', 'needConfirmation'],
    setup() {
        const authStore = useAuth();
        const { isReadonly } = storeToRefs(authStore);
        return {
            isReadonly,
            SharedBookStatus,
        };
    },
    computed: {
        sortOptions() {
            return [
                {
                    value: `label:${OrderDirection.ASC}`,
                    label: this.$t('contactBooks.controls.order.nameAZ'),
                },
                {
                    value: `label:${OrderDirection.DESC}`,
                    label: this.$t('contactBooks.controls.order.nameZA'),
                },
                {
                    value: `created_at:${OrderDirection.ASC}`,
                    label: this.$t('contactBooks.controls.order.firstCreated'),
                },
                {
                    value: `created_at:${OrderDirection.DESC}`,
                    label: this.$t('contactBooks.controls.order.lastCreated'),
                },
            ];
        },
        sort() {
            return `${this.order.column}:${this.order.mode}`;
        },
    },
    methods: {
        onSortChange(newSort) {
            if (this.sort === newSort)
                return;
            const [column, mode] = newSort.split(':');
            this.$emit('changeOrder', {
                column,
                mode,
            });
        },
    },
});
