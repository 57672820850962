import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5de9a7a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "contacts-view" };
const _hoisted_2 = { class: "flex justify-end gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contacts_view_modal_profile_info = _resolveComponent("contacts-view-modal-profile-info");
    const _component_contacts_view_field = _resolveComponent("contacts-view-field");
    const _component_or_button = _resolveComponent("or-button");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modalComponent",
        "data-test": "contact-info-modal",
        size: "s"
    }, _createSlots({
        header: _withCtx(() => [
            _createVNode(_component_contacts_view_modal_profile_info, {
                "first-name": _ctx.firstName,
                "last-name": _ctx.lastName,
                "profile-image": _ctx.profilePictureUrl
            }, null, 8, ["first-name", "last-name", "profile-image"])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedSchemaItems, (field) => {
                    return (_openBlock(), _createBlock(_component_contacts_view_field, {
                        key: field.order,
                        "field-schema": field,
                        data: _ctx.contact[field.key]
                    }, null, 8, ["field-schema", "data"]));
                }), 128))
            ])
        ]),
        _: 2
    }, [
        (!_ctx.isReadonly)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_or_button, {
                            "data-test": "edit-contact-info-button",
                            type: "outline",
                            content: _ctx.$t('contacts.edit'),
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('edit', _ctx.contact.contactId)))
                        }, null, 8, ["content"])
                    ])
                ]),
                key: "0"
            }
            : undefined
    ]), 1536));
}
