import _ from 'lodash';
import { computed, reactive, defineComponent, toRaw } from 'vue';
import { getEmptyContactFormValuesV2, isPropertyEmpty } from '@/utils';
import { ContactFormFieldV2 } from './ContactFormField';
export default defineComponent({
    name: 'ContactsForm',
    components: {
        ContactFormField: ContactFormFieldV2,
    },
    props: {
        fieldSchemas: {
            type: Array,
            required: true,
        },
        defaultValue: {
            type: Object,
            required: false,
            default: null,
        },
    },
    setup(props, { emit }) {
        const cleanDefaultValue = reactive(_.filter(props.defaultValue, field => !isPropertyEmpty(field.value)).map(toRaw));
        const localValues = reactive((() => {
            const emptyValues = getEmptyContactFormValuesV2(props.fieldSchemas);
            const clonedValues = _.cloneDeep(props.defaultValue);
            return _.map(emptyValues, value => {
                const clonedValue = _.find(clonedValues, cloned => value.schemaId === cloned.schemaId);
                if (clonedValue && clonedValue.id) {
                    if (isPropertyEmpty(clonedValue.value))
                        clonedValue.value = value.value;
                    return clonedValue;
                }
                else {
                    return value;
                }
            });
        })());
        const cleanLocalValue = computed(() => {
            return _.filter(localValues, field => !isPropertyEmpty(field.value)).map(toRaw);
        });
        const isChanged = computed(() => {
            return cleanDefaultValue.length !== cleanLocalValue.value.length
                || _.differenceWith(cleanLocalValue.value, cleanDefaultValue, _.isEqual).length > 0;
        });
        const handleUpdate = (key, value) => {
            const fieldToUpdate = _.find(localValues, value => value.schemaId === key);
            if (fieldToUpdate) {
                fieldToUpdate.value = value;
            }
            else {
                localValues.push({
                    schemaId: key,
                    value,
                });
            }
        };
        const submit = () => {
            emit('submit', localValues);
        };
        return {
            localValues,
            isChanged,
            handleUpdate,
            submit,
        };
    },
});
