import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-451cfba6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "tabs-container h-full w-full" };
const _hoisted_2 = { class: "layout-column overflow-y-auto overflow-x-hidden h-full" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_or_tabs = _resolveComponent("or-tabs");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isAnyTabSelected)
            ? (_openBlock(), _createBlock(_component_or_tabs, {
                key: 0,
                modelValue: _ctx.selectedTab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedTab) = $event)),
                items: _ctx.tabsItems,
                variant: _ctx.isMobile ? 'fitted' : 'default',
                class: _normalizeClass(["min-h-[36px]", _ctx.isCustomFullPage ? 'full-screen-tabs' : ''])
            }, {
                [_ctx.selectedTab]: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_router_view)
                    ])
                ]),
                _: 2
            }, 1032, ["modelValue", "items", "variant", "class"]))
            : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ]));
}
