import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c30666e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "container" };
const _hoisted_2 = {
    key: 0,
    class: "form"
};
const _hoisted_3 = {
    class: "title",
    "data-test": "schema-editor-title"
};
const _hoisted_4 = { class: "form-group" };
const _hoisted_5 = { class: "form-group" };
const _hoisted_6 = { class: "form-group" };
const _hoisted_7 = {
    key: 1,
    class: "preview"
};
const _hoisted_8 = { class: "type" };
const _hoisted_9 = {
    class: "label",
    "data-test": "trigger-label"
};
const _hoisted_10 = {
    class: "trigger",
    "data-test": "trigger"
};
const _hoisted_11 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b;
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_input = _resolveComponent("or-input");
    const _component_icon_select = _resolveComponent("icon-select");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_switch = _resolveComponent("or-switch");
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showForm)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.isNew ? _ctx.$t('contactBooks.schemaEditor.newTrigger') : _ctx.$t('contactBooks.schemaEditor.trigger')), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_or_select, {
                        modelValue: _ctx.localState.trigger,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localState.trigger) = $event)),
                        "data-test": "schema-editor-trigger-id-select",
                        label: _ctx.$t('contactBooks.schemaEditor.triggerId'),
                        options: _ctx.triggers
                    }, null, 8, ["modelValue", "label", "options"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_or_input, {
                        modelValue: _ctx.localState.label,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localState.label) = $event)),
                        "data-test": "schema-editor-trigger-name-input",
                        label: _ctx.$t('contactBooks.schemaEditor.triggerLabel'),
                        "is-invalid": _ctx.v$.label.$error,
                        error: (_a = _ctx.v$.label.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
                        onBlur: _ctx.v$.label.$touch
                    }, null, 8, ["modelValue", "label", "is-invalid", "error", "onBlur"]),
                    _createVNode(_component_icon_select, {
                        modelValue: _ctx.localState.icon,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.localState.icon) = $event)),
                        class: "select-icon",
                        "data-test": "schema-editor-trigger-icon-select",
                        label: _ctx.$t('contactBooks.schemaEditor.triggerIcon'),
                        "is-invalid": _ctx.v$.icon.$error,
                        error: (_b = _ctx.v$.icon.$errors[0]) === null || _b === void 0 ? void 0 : _b.$message
                    }, null, 8, ["modelValue", "label", "is-invalid", "error"]),
                    _createVNode(_component_or_button, {
                        class: "save-button",
                        "data-test": "schema-editor-save-trigger-button",
                        "is-disabled": !_ctx.isChanged
                            ||
                                _ctx.v$.$invalid,
                        content: _ctx.isNew ? _ctx.$t('common.add') : _ctx.$t('common.save'),
                        onClick: _ctx.handleSave
                    }, null, 8, ["is-disabled", "content", "onClick"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_or_switch, {
                        modelValue: _ctx.localState.initialMessageRequired,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.localState.initialMessageRequired) = $event)),
                        label: _ctx.$t('contactBooks.schemaEditor.requireInitialMessage')
                    }, null, 8, ["modelValue", "label"])
                ])
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_or_icon, {
                        icon: _ctx.trigger.icon
                    }, null, 8, ["icon"]),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.trigger.label), 1)
                ]),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.trigger.trigger), 1)
            ])),
        _createElementVNode("div", _hoisted_11, [
            (!_ctx.showForm)
                ? (_openBlock(), _createBlock(_component_or_icon_button, {
                    key: 0,
                    "data-test": "edit-trigger-icon",
                    icon: "edit",
                    "icon-color": "neutral-4",
                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('edit')))
                }))
                : _createCommentVNode("", true),
            _createVNode(_component_or_icon_button, {
                "data-test": "delete-trigger-icon",
                icon: "delete",
                "icon-color": "neutral-4",
                onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('delete')))
            })
        ])
    ]));
}
