import { UserRoles } from '@onereach/auth-ui-module';
import { OrTabsV3 as OrTabs, useResponsive } from '@onereach/ui-components';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import useAuthStore from '@/stores/auth';
export default defineComponent({
    name: 'RoutingTabsContainer',
    components: {
        OrTabs,
    },
    setup() {
        const route = useRoute();
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore);
        const currentRouteName = computed(() => route.name);
        const isCustomFullPage = computed(() => ['all contacts v2', 'contacts v2'].includes(route.name));
        const { isMobile } = useResponsive();
        return {
            currentRouteName,
            isCustomFullPage,
            isMobile,
            role,
        };
    },
    data() {
        return {
            selectedTab: '',
        };
    },
    computed: {
        tabsItems() {
            const items = [
                {
                    value: 'books v2 index',
                    label: this.$t('tabsItems.books'),
                },
                {
                    value: 'all contacts v2',
                    label: this.$t('tabsItems.contacts'),
                },
                {
                    value: 'field schemas',
                    label: this.$t('tabsItems.fields'),
                },
            ];
            if (this.role === UserRoles.superAdmin || this.role === UserRoles.admin) {
                items.push({
                    value: 'settings',
                    label: this.$t('titles.settings'),
                });
            }
            return items;
        },
        isAnyTabSelected() {
            return this.tabsItems.map(item => item.value).includes(this.currentRouteName);
        },
    },
    watch: {
        currentRouteName: {
            handler(val) {
                this.selectedTab = val;
            },
            immediate: true,
        },
        selectedTab(val) {
            if (this.$route.name === val) {
                return;
            }
            this.$router.replace({ name: val });
        },
    },
});
