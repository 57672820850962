import { AuthPopover } from '@onereach/auth-ui-module';
import { ServiceNavigationHeader } from '@onereach/service-navigation';
import { OrNotificationV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useAuth } from '@/stores';
import { getMenuItems } from '../utils';
export default defineComponent({
    name: 'AppPage',
    components: {
        ServiceNavigationHeader,
        AuthPopover,
        OrNotificationV3,
    },
    props: {
        token: {
            type: String,
            required: false,
            default: null,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        config: {
            type: Object,
            required: true,
        },
        serviceTranslations: {
            type: Object,
            required: false,
            default: null,
        },
        showError: {
            type: Boolean,
            required: false,
            default: false,
        },
        errorText: {
            type: String,
            required: false,
            default: null,
        },
        accountId: {
            type: String,
            required: false,
            default: null,
        },
    },
    emits: ['update:showError'],
    setup(props, { emit }) {
        const isErrorShown = useVModel(props, 'showError', emit);
        const route = useRoute();
        const isV2 = computed(() => { var _a; return (_a = route.path) === null || _a === void 0 ? void 0 : _a.startsWith('/books'); });
        const isFullPage = computed(() => [
            'create book v2',
            'import book v2',
            'all contacts v2',
            'contacts v2',
        ].includes(route.name));
        const authStore = useAuth();
        const { authorized } = storeToRefs(authStore);
        return {
            isV2,
            isFullPage,
            authorized,
            isErrorShown,
        };
    },
    computed: {
        breadcrumbs() {
            return this.$route.matched
                .map(({ meta }) => meta.breadcrumb)
                .filter(Boolean)
                .map((breadcrumb) => {
                return _.isFunction(breadcrumb) ? breadcrumb.call(this) : breadcrumb;
            });
        },
        menuItems() {
            return getMenuItems(this.$t);
        },
    },
    async mounted() {
        const unwatch = this.$watch('authorized', async () => {
            unwatch();
        });
    },
});
