import { defineStore } from 'pinia';
import ContactsApi from '@/api/ContactsApi';
import { v4 } from 'uuid';
import _ from 'lodash';
const useContactsStore = defineStore('contactsV1', {
    state: () => ({
        collections: {},
        contacts: {},
    }),
    getters: {
        getContactsByBookName: (state) => {
            return (bookName) => {
                return state.collections[bookName]
                    ? state.collections[bookName]
                    : {};
            };
        },
        getContactById: (state) => (contactId) => state.contacts[contactId],
    },
    actions: {
        async getContacts(payload) {
            const { bookName, size = 25, from = 0, searchStr: searchPhrase, columnToOrderBy: columnToOrderBy, orderMode: orderMode, filterId, } = payload;
            const { data, total } = await ContactsApi.searchContacts({
                bookName,
                searchPhrase,
                size,
                v: 2,
                from,
                sort: `${columnToOrderBy}:${orderMode}`,
                filterId,
            });
            this.collections = {
                ...this.collections,
                [bookName]: {
                    items: data,
                    total,
                },
            };
            data.forEach(contact => this.contacts[contact.contactId] = contact);
        },
        //TODO: searchContacts returns 500 for big tables, must be fixed in SDK
        /*
        async getAllContactsProjection<T extends(keyof Contact)[]>(
          payload: GetContactsProjectionDTO<T>
        ): Promise<Pick<Contact, T[number]>[]> {
          const data = await ContactsApi.searchContacts({
            ...payload,
            v: 2,
          });
    
          const typedData = data as (Pick<Contact, T[number]> & { contactId?: string; })[];
          if (typedData.length > 0 && 'contactId' in typedData[0]) {
            typedData.forEach(contact => {
              const id = contact.contactId as string;
              const prevContact = this.contacts[id];
              this.contacts[id] = {
                ...prevContact,
                ...contact,
              };
            });
          }
    
          return typedData;
        },
         */
        //TODO: remove after fix in SDK
        async getAllContactsProjection(payload, pageSize = 100, maxSameTimeRequestsCount = 10) {
            const { total } = await ContactsApi.searchContacts({
                bookName: payload.bookName,
                size: 0,
                from: 0,
                v: 2,
                projection: [],
            });
            const results = [];
            const requestsPayloads = _.range(0, total, pageSize).map(from => ({
                ...payload,
                v: 2,
                from,
                size: pageSize,
            }));
            const chunkedPayloads = _.chunk(requestsPayloads, maxSameTimeRequestsCount);
            for (const payloadChunk of chunkedPayloads) {
                const promisesChunk = payloadChunk.map(requestPayload => ContactsApi.searchContacts(requestPayload));
                results.push(await Promise.all(promisesChunk));
            }
            const flattenResults = _.flattenDeep(results);
            const typedData = _.uniq(_.flatMap(flattenResults, result => result.data));
            if (typedData.length > 0 && 'contactId' in typedData[0]) {
                typedData.forEach(contact => {
                    const id = contact.contactId;
                    const prevContact = this.contacts[id];
                    this.contacts[id] = {
                        ...prevContact,
                        ...contact,
                    };
                });
            }
            return typedData;
        },
        async updateContact({ contactData, bookName }) {
            await ContactsApi.updateContact({
                contactData,
                bookName,
            });
        },
        async createContact({ contactData, bookName }) {
            // eslint-disable-next-line
            const { contactId, ...contactDataToSend } = contactData;
            await ContactsApi.createContact({
                contactData: {
                    contactId: v4(),
                    ...contactDataToSend,
                },
                bookName,
            });
        },
        async deleteContacts({ contactIds, bookName }) {
            await ContactsApi.deleteContact({
                contactIds,
                bookName,
            });
            contactIds.forEach(id => delete this.contacts[id]);
        },
        async deleteAllContacts(bookName) {
            //TODO: ADD DELETING ALL CONTACTS TO SDK
            const recordsCount = (await ContactsApi.getContactRecordsCount({
                bookName,
            })).count;
            const contacts = await ContactsApi.getContactsList({
                bookName,
                from: 0,
                size: recordsCount,
                projection: ['contactId'],
            });
            const contactIds = contacts.data.map(({ contactId }) => contactId);
            await ContactsApi.deleteContact({
                contactIds,
                bookName,
            });
            contactIds.forEach(id => delete this.contacts[id]);
        },
        async mergeContacts({ toContact, fromContacts, bookName }) {
            await ContactsApi.mergeContacts({
                toContact,
                fromContacts,
                bookName,
            });
        },
    },
});
export default useContactsStore;
