import _ from 'lodash';
import { ContactsSettingsApi } from '@onereach/sdk';
import config from '@/config';
import { useAuth } from '@/stores';
const instance = new ContactsSettingsApi({
    userTokenFactory: () => {
        return `USER ${_.get(useAuth(), 'token')}`;
    },
    httpGatewayUrl: config.GATEWAY_URL,
    providersAccountId: config.PROVIDERS_ACCOUNT_ID,
});
export default instance;
