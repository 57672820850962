import { AUTH_API_INJECTION_TOKEN, ACCOUNTS_API_INJECTION_TOKEN, USERS_API_INJECTION_TOKEN, APP_CONFIG, ExpirationOverlay, UserRoles, } from '@onereach/auth-ui-module';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, watch } from 'vue';
import { provide } from 'vue-demi';
import { useRoute } from 'vue-router';
import { AppPage } from '@/components';
import config from '@/config';
import { MigrationsStatus } from '@/constants';
import { TranslatableError } from '@/errors';
import SentryApi from '@/sentry';
import { useContactBooksV1, useContactBooksV2, useContactsV1, useContactsV2, useAuth, useSchemaPresetsStore, useFieldSchemas, } from '@/stores';
import * as stores from '@/stores';
import pkg from '../package.json';
import AccountsApi from './api/AccountsApi';
import AuthApi from './api/Auth';
import UsersApi from './api/UsersApi';
export default defineComponent({
    components: {
        AppPage,
        ExpirationOverlay,
    },
    setup() {
        // Fix of Pinia plugin disabling
        _.entries(stores)
            .filter(([key, value]) => key.startsWith('use') && _.isFunction(value))
            .forEach(([, useCurrentStore]) => useCurrentStore());
        const route = useRoute();
        const isV2 = computed(() => { var _a; return (_a = route.name) === null || _a === void 0 ? void 0 : _a.includes('v2'); });
        const contactBooksStoreV1 = useContactBooksV1();
        const contactBooksStoreV2 = useContactBooksV2();
        const { isLoading: isContactBooksLoadingV1, migrationsStatus: migrationsStatusV1, } = storeToRefs(contactBooksStoreV1);
        const { isLoading: isContactBooksLoadingV2, migrationsStatus: migrationsStatusV2, } = storeToRefs(contactBooksStoreV2);
        const { getBookFields: getBookFieldsV1, getTriggers: getTriggersV1, } = contactBooksStoreV1;
        const schemaPresetsStore = useSchemaPresetsStore();
        const { getSchemaPresets } = schemaPresetsStore;
        const { isLoading: isSchemaPresetsLoading } = storeToRefs(schemaPresetsStore);
        const fieldSchemasStore = useFieldSchemas();
        const { isLoading: isFieldSchemasLoading } = storeToRefs(fieldSchemasStore);
        const { getFieldSchemas } = fieldSchemasStore;
        const { isLoading: isContactsLoadingV1 } = storeToRefs(useContactsV1());
        const { isLoading: isContactsLoadingV2 } = storeToRefs(useContactsV2());
        const isContactBooksLoading = computed(() => isV2.value ? isContactBooksLoadingV2.value : isContactBooksLoadingV1.value);
        const isContactsLoading = computed(() => isV2.value ? isContactsLoadingV2.value : isContactsLoadingV1.value);
        const migrationsStatus = computed(() => isV2.value ? migrationsStatusV2.value : migrationsStatusV1.value);
        const authStore = useAuth();
        const { authorized: isAuthorized, username, identityProvider, token, cookiesAnotherUser, cookiesNoRights, cookiesExpired, userId, multiUserId, accountId, role, } = storeToRefs(authStore);
        const { updateCookieExpiration, getUserNameForLockScreen, } = authStore;
        //provide api services to AuthPopover
        provide(AUTH_API_INJECTION_TOKEN, AuthApi);
        provide(ACCOUNTS_API_INJECTION_TOKEN, AccountsApi);
        provide(USERS_API_INJECTION_TOKEN, UsersApi);
        provide(APP_CONFIG, config);
        watch([isV2, isAuthorized, migrationsStatus], async ([newIsV2, newIsAuthorized, newMigrationsStatus]) => {
            if (newIsAuthorized && newMigrationsStatus === MigrationsStatus.RESOLVED) {
                await (newIsV2 ? getFieldsAndTriggersV2 : getFieldsAndTriggersV1)();
            }
        });
        const getFieldsAndTriggersV1 = async () => {
            await Promise.all([
                getTriggersV1(),
                getBookFieldsV1(),
            ]);
        };
        const getFieldsAndTriggersV2 = async () => {
            await Promise.all([
                getSchemaPresets(),
                getFieldSchemas(),
            ]);
        };
        return {
            isContactBooksLoading,
            isContactsLoading,
            isSchemaPresetsLoading,
            isFieldSchemasLoading,
            isAuthorized,
            multiUserId,
            cookiesAnotherUser,
            cookiesNoRights,
            username,
            accountId,
            authUrl: config.AUTH_UI_URL,
            identityProvider,
            token,
            userId,
            cookiesExpired,
            updateCookieExpiration,
            getUserNameForLockScreen,
            isV2,
            role,
        };
    },
    data() {
        return {
            config,
            serviceTranslations: pkg.projectConfig.translations,
            lockScreenUsername: '',
            errorText: this.$t('errors.commonText'),
            showError: false,
        };
    },
    computed: {
        isLoading() {
            return _.some([
                this.isContactBooksLoading,
                this.isContactsLoading,
                this.isSchemaPresetsLoading,
                this.isFieldSchemasLoading,
            ], val => val === true);
        },
        userHasPermission() {
            return this.role && this.role !== UserRoles.guest;
        },
    },
    watch: {
        async isAuthorized(value) {
            if (value) {
                this.lockScreenUsername = await this.getUserNameForLockScreen();
            }
        },
    },
    errorCaptured(e) {
        if (e instanceof TranslatableError) {
            // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
            this.errorText = this.$t(e.translationKey);
            SentryApi.captureException(e.innerError);
        }
        else {
            this.errorText = this.$t('errors.commonText');
            SentryApi.captureException(e);
        }
        this.showError = true;
        return false;
    },
    methods: {
        cookiesRenewHandler() {
            this.updateCookieExpiration();
        },
    },
});
