import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e6dfeed"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
const _hoisted_2 = { class: "cell name" };
const _hoisted_3 = { class: "book-name typography-headline-4 text-on-background dark:text-on-background-dark" };
const _hoisted_4 = { class: "cell contacts" };
const _hoisted_5 = { class: "cell date" };
const _hoisted_6 = { class: "cell state" };
const _hoisted_7 = { class: "cell actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_status = _resolveComponent("contact-books-status");
    const _component_contact_book_overflow_menu = _resolveComponent("contact-book-overflow-menu");
    const _component_or_card = _resolveComponent("or-card");
    return (_openBlock(), _createElementBlock("a", {
        href: _ctx.needConfirmation ? undefined : _ctx.resolvedRoute,
        class: _normalizeClass(_ctx.needConfirmation ? 'cursor-default' : 'cursor-pointer'),
        "data-test": "contact-books-table-item",
        onClick: _cache[4] || (_cache[4] =
            //@ts-ignore
            (...args) => (_ctx.beforeOpen && _ctx.beforeOpen(...args)))
    }, [
        _createVNode(_component_or_card, {
            interactive: "",
            class: "row flex !flex-row !typography-body-2-regular !text-outline dark:!text-outline-dark overflow-hidden"
        }, {
            default: _withCtx(() => {
                var _a;
                return [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.label), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$tc('contactBooks.contact', (_a = _ctx.item.contactsCount) !== null && _a !== void 0 ? _a : 0)), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.updatedAtTimeString), 1),
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_contact_books_status, {
                            sharer: _ctx.item.sharedBy,
                            state: _ctx.bookSharingState
                        }, null, 8, ["sharer", "state"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_contact_book_overflow_menu, {
                            "is-readonly": _ctx.isReadonly,
                            "share-status": _ctx.bookSharingState,
                            class: "ml-auto",
                            "is-v2": "",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"])),
                            onDelete: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete', _ctx.item.id))),
                            onDeleteWithContacts: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('deleteWithContacts', _ctx.item.id))),
                            onOpen: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('open', _ctx.item.id)))
                        }, null, 8, ["is-readonly", "share-status"])
                    ])
                ];
            }),
            _: 1
        })
    ], 10, _hoisted_1));
}
