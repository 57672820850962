import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-13a07d96"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "array-of-strings" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (val) => {
            return (_openBlock(), _createElementBlock("span", {
                key: val,
                class: "string-item"
            }, _toDisplayString(val), 1));
        }), 128))
    ]));
}
