import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6163e77e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "contact-view-field"
};
const _hoisted_2 = { class: "contact-view-field-title" };
const _hoisted_3 = { class: "contact-view-field-content" };
const _hoisted_4 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.alwaysShow || !_ctx.isEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fieldSchema.label), 1),
            _createElementVNode("div", _hoisted_3, [
                (_ctx.isComponentImplemented)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                        key: 0,
                        "field-schema": _ctx.fieldSchema,
                        data: _ctx.data || undefined
                    }, null, 8, ["field-schema", "data"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.fieldSchema.typeOf), 1))
            ])
        ]))
        : _createCommentVNode("", true);
}
