import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1499f67a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "header" };
const _hoisted_2 = {
    class: "name",
    "data-test": "contact-book-item-name"
};
const _hoisted_3 = { class: "footer" };
const _hoisted_4 = { class: "count" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_status = _resolveComponent("contact-books-status");
    const _component_contact_book_overflow_menu = _resolveComponent("contact-book-overflow-menu");
    const _component_or_card = _resolveComponent("or-card");
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createBlock(_component_router_link, {
        to: {
            name: 'contacts',
            params: {
                bookName: _ctx.item.name
            }
        }
    }, {
        default: _withCtx(() => [
            _createVNode(_component_or_card, {
                class: "book-item",
                "data-test": "contact-book-item"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.name), 1)
                    ]),
                    _createVNode(_component_contact_books_status, {
                        sharer: _ctx.item.source.accountName,
                        state: _ctx.bookState
                    }, null, 8, ["sharer", "state"]),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$tc('contactBooks.contact', _ctx.itemsCount)), 1),
                        _createVNode(_component_contact_book_overflow_menu, {
                            "is-shared-book": _ctx.item.source.accountName !== undefined,
                            onDelete: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('delete', _ctx.item.name))),
                            onMigrate: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('migrate', _ctx.item.name))),
                            onOpen: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('open', _ctx.item.name))),
                            onUpdate: _ctx.handleUpdate
                        }, null, 8, ["is-shared-book", "onUpdate"])
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["to"]));
}
