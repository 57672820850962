import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7090153b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "books-grid-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_grid_item = _resolveComponent("contact-books-grid-item");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_contact_books_grid_item, {
                key: item.bookId,
                item: item,
                onDelete: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('delete', $event))),
                onMigrate: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('migrate', $event))),
                onOpen: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('open', $event))),
                onShare: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('share', $event)))
            }, null, 8, ["item"]));
        }), 128))
    ]));
}
